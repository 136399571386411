define(['lodash', 'coreUtilsLib'], function (_, coreUtilsLib) {
    'use strict';

    function initOldRemarketingPixel(accountId) {
        if (window._fbq) {
            return;
        }

        const _fbq = window._fbq || (window._fbq = []);
        if (!_fbq.loaded) {
            const fbds = window.document.createElement('script');
            fbds.async = true;
            fbds.src = '//connect.facebook.net/en_US/fbds.js';
            const s = window.document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(fbds, s);
            _fbq.loaded = true;
        }
        _fbq.push(['addPixelId', accountId]);
        window._fbq.push(['track', 'PixelInitialized', {}]);
    }

    /*
     Implements https://developers.facebook.com/docs/marketing-api/facebook-pixel/v2.5

     Original:

     !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
     n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
     n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
     t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
     document,'script','//connect.facebook.net/en_US/fbevents.js');
     // Insert Your Facebook Pixel ID below.
     fbq('init', '<FB_PIXEL_ID>');
     fbq('track', 'PageView');

     */

    function initNewRemarketingPixel(pixelId) {
        let fbqGlobal, scriptElem, firstScriptElem;

        if (!window.fbq) {
            fbqGlobal = window.fbq = function () {
                if (fbqGlobal.callMethod) {
                    fbqGlobal.callMethod.apply(fbqGlobal, arguments);
                } else {
                    fbqGlobal.queue.push(arguments);
                }
            };

            if (!window._fbq) {
                window._fbq = fbqGlobal;
            }

            fbqGlobal.push = fbqGlobal;
            fbqGlobal.loaded = true;
            fbqGlobal.version = '2.0';
            fbqGlobal.agent = 'plwix';
            fbqGlobal.queue = [];
            scriptElem = window.document.createElement('script');
            scriptElem.async = true;
            scriptElem.src = '//connect.facebook.net/en_US/fbevents.js';
            firstScriptElem = window.document.getElementsByTagName('script')[0];
            firstScriptElem.parentNode.insertBefore(scriptElem, firstScriptElem);
        }

        window.fbq('init', pixelId);
        window.fbq('track', 'PageView');
    }

    function isValidPixelId(pixelId) {
        return Number(pixelId) && _.isString(pixelId) && !_.isEmpty(pixelId);
    }

    function isUsingNewRemarketingPixel(siteData) {
        return siteData.isUsingUrlFormat(coreUtilsLib.siteConstants.URL_FORMATS.SLASH);
    }

    function shouldFireEvent(siteData, eventType, eventName) {
        return isUsingNewRemarketingPixel(siteData) && window.fbq && _.isString(eventType) && _.isString(eventName);
    }

    function fireCustomEvent(siteData, eventType, eventName, data) {
        if (shouldFireEvent(siteData, eventType, eventName)) {
            window.fbq(eventType, eventName, data || {});
        }
    }

    return {
        //To be sent via siteAPI (to be called externally only a single time)
        initRemarketingPixel(siteData, pixelId) {
            if (!isValidPixelId(pixelId)) {
                return;
            }

            if (isUsingNewRemarketingPixel(siteData)) {
                initNewRemarketingPixel(pixelId);
            } else {
                initOldRemarketingPixel(pixelId);
            }
        },
        //TO be sent via sdk (can be called multiple times)
        initPixelId(siteData, pixelId) {
            if (!isValidPixelId(pixelId) || !isUsingNewRemarketingPixel(siteData)) {
                return;
            }
            this.initRemarketingPixel(siteData, pixelId);
        },
        //TO be sent via sdk (can be called multiple times)
        fireRemarketingPixel(siteData, eventName, data) {
            fireCustomEvent(siteData, 'track', eventName, data);
        },

        fireCustomEvent(siteData, eventType, eventName, data) {
            fireCustomEvent(siteData, eventType, eventName, data);
        }
    };
});
